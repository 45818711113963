.form-style{
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 400px;
    margin-right: 00px;
    margin-top: 20px;
    color: #00342C;
}

.FadeLoader{
    position: absolute;
    left: 50%;
    top: 400PX;
}

.option{
    flex-direction: row;
    margin-bottom: 20px;
    
}

.li{
    z-index: 1;
    color: black;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    background-color: #fff;
    transition: background-color 0.3s;
}

.search-box{
  position: absolute;
  z-index: 1000;
  cursor:text;
  top: 180px;
  left: 50px;
  padding: 0px 8px;
  width: 500px;
  height: 30px;
  border-radius: 8px;
  font-size: 17px;
}
